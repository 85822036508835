import gql from 'graphql-tag';
import {
  analyticArgumentsOnlineMedia,
  analyticArgumentsQueryOnlineMedia
} from '../analyticArgument';

const onlineMediaStreamOutputWithPagination = `
  result {
    originalId
    createdAt
    datePublished
    displayPicture
    title
    content
    link
    people
    place
    quote
    sentiment
    sourceName
    updatedAt
    writer
    prValues
    clippingList{
      id
      name
      isActive
      createdAt
    }
    labelArticleList{
      id
      name
      displayName
      colorType
      isActiveanalyticArgumentsQueryOnlineMediaOnlineMedia
      createdAt
      clippingId
    }
    listOfClippingId
  }
  pagination{
    next 
    hasNext
  }
`;

const numericOutput = `
  name
  value
`;

const chronologicalOutput = `
  contentType
  values {
    timestamp
    value
  }
`;

export const GET_ALL_MEDIA = gql`
  query onlineMedia_getAllMedia {
    onlineMedia_getAllMedia {
      media
    }
  }
`;

export const GET_TOTAL_ARTICLE_BY_MEDIA = gql`
  query onlineMedia_getTotalArticleByMedia(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getTotalArticleByMedia(${analyticArgumentsOnlineMedia}) {
      media
      total
      sentimentPositive
      sentimentNegative
      sentimentNeutral
    }
  }
`;

export const GET_WORD_CLOUD_NEWS = gql`
  query onlineMedia_getWordCloudNews(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getWordCloudNews(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_ONLINE_MEDIA_SENTIMENT_ANALYSIS = gql`
  query onlineMedia_getSentimentAnalysis(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getSentimentAnalysis(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOP_PEOPLE = gql`
  query onlineMedia_getTopPeople(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getTopPeople(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOP_LOCATION = gql`
  query onlineMedia_getTopLocation(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getTopLocation(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOTAL_ARTICLE = gql`
  query onlineMedia_getTotalArticle(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getTotalArticle(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOTAL_MEDIA = gql`
  query onlineMedia_getTotalMedia(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getTotalMedia(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOTAL_PR_VALUES = gql`
  query onlineMedia_getTotalPRValues(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getTotalPRValues(${analyticArgumentsOnlineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_CHRONOLOGICAL_ARTICLE = gql`
  query onlineMedia_getChronologicalArticle(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getChronologicalArticle(${analyticArgumentsOnlineMedia}) {
      ${chronologicalOutput}
    }
  }
`;

export const GET_CHRONOLOGICAL_MEDIA = gql`
  query onlineMedia_getChronologicalMedia(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getChronologicalMedia(${analyticArgumentsOnlineMedia}) {
      ${chronologicalOutput}
    }
  }
`;

export const GET_CHRONOLOGICAL_SENTIMENT = gql`
  query onlineMedia_getChronologicalSentiment(${analyticArgumentsQueryOnlineMedia}) {
    onlineMedia_getChronologicalSentiment(${analyticArgumentsOnlineMedia}) {
      ${chronologicalOutput}
    }
  }
`;

export const GET_ARTICLE = gql`
  query onlineMedia_getArticle(${analyticArgumentsQueryOnlineMedia}, $next: String) {
    onlineMedia_getArticle(${analyticArgumentsOnlineMedia}, next: $next) {
      ${onlineMediaStreamOutputWithPagination}
    }
  }
`;

export const GET_ARTICLE_V2 = gql`
  query onlineMedia_getArticleV2(${analyticArgumentsQueryOnlineMedia}, $page: Int!, $limit: Int!) {
    onlineMedia_getArticleV2(${analyticArgumentsOnlineMedia}, page: $page, limit: $limit) {
      limit
      page
      totalPages
      totalRows
      result {
        originalId
        keywordList
        createdAt
        datePublished
        displayPicture
        title
        content
        link
        people
        place
        quote
        sentiment
        sourceName
        updatedAt
        writer
        prValues
        symbol
        isEdited
        clippingList{
          id
          name
          isActive
          createdAt
        }
        labelArticleList{
          id
          name
          displayName
          colorType
          isActive
          createdAt
          clippingId
        }
        listOfClippingId
      }
    }
  }
`;

export const GET_AUTO_CLIPPING = gql`
  query onlineMedia_getAutoClippings($projectId: Id!) {
    onlineMedia_getAutoClippings(projectId: $projectId) {
      id
      name
      clippings {
        id
        name
        createdAt
      }
      phrases
      startDate
      createdAt
    }
  }
`;

export const GET_AUTO_CLIPPING_BY_ID = gql`
  query onlineMedia_getAutoClippingById($autoClippingId: Id!) {
    onlineMedia_getAutoClippingById(autoClippingId: $autoClippingId) {
      id
      name
      clippings {
        id
        name
        createdAt
      }
      phrases
      startDate
      createdAt
    }
  }
`;

export const CHECK_BACKGROUND_PROCESS_ONM = gql`
  query onlineMedia_checkBackgroundProcessOnm($projectId: Id!) {
    onlineMedia_checkBackgroundProcessOnm(projectId: $projectId) {
      sentimentArticleUpdater {
        listUpdateSentimentArticle {
          id
          projectId
          user {
            userId
            username
            company
          }
          start
          end
          timezoneOffset
          sentimentUpdate
          processedBatches
          totalBatches
          totalData
          processedData
          status
        }
      }
    }
  }
`;

export const GET_LABEL_ARTICLE = gql`
  query labellingArticle_getLabelArticle($projectId: Id!, $clippingId: Id!) {
    labellingArticle_getLabelArticle(
      projectId: $projectId
      clippingId: $clippingId
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
      clippingId
    }
  }
`;

export const GET_LABEL_ARTICLE_V2 = gql`
  query labellingArticle_getLabelArticleV2($projectId: Id!) {
    labellingArticle_getLabelArticleV2(projectId: $projectId) {
      displayName
      name
      colorType
      displayName
      id
      __typename
    }
  }
`;

export const GET_CLIPPING_PHRASE_STATUS = gql`
  query onlineMedia_getClippingPhraseStatus($projectId: Id!) {
    onlineMedia_getClippingPhraseStatus(projectId: $projectId) {
      id
      name
      phrases
      clippingPhrases {
        id
        name
        isFirstCrawlerProcess
        createdAt
      }
      createdAt
    }
  }
`;

export const CHECK_ON_DEMAND_ONLINE_MEDIA_SEARCH = gql`
  query firstTimeData_checkOnDemandOnlineMediaSearch($projectId: Id!) {
    firstTimeData_checkOnDemandOnlineMediaSearch(projectId: $projectId) {
      id
      phrases
      totalJob
      status
      processedArticles
      createdAt
    }
  }
`;

export const GET_COMPARISON_ONLINE_MEDIA_NAMESPACE_LIST = gql`
  query comparisonOnlineMedia_getComparisonNamespaceList($projectId: Id!) {
    comparisonOnlineMedia_getComparisonNamespaceList(projectId: $projectId) {
      id
      namespace
      displayName
      isDefault
      createdAt
      colors
      sourceTypeOnm
      __typename
    }
  }
`;

export const GET_COMPARISON_DEFAULT_ONLINE_MEDIA = gql`
  query comparisonOnlineMedia_getComparisonDefault($projectId: Id!) {
    comparisonOnlineMedia_getComparisonDefault(projectId: $projectId) {
      id
      namespace
      displayName
      isDefault
      createdAt
    }
  }
`;

export const GET_BRAND_SENTIMENT_ONLINE_MEDIA = gql`
  query comparisonOnlineMedia_getBrandSentiment(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $validationList: [StreamValidation!]
  ) {
    comparisonOnlineMedia_getBrandSentiment(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      validationList: $validationList
    ) {
      groupName
      positive
      negative
      neutral
      __typename
    }
  }
`;

export const GET_BRAND_POPULARITY_ONLINE_MEDIA = gql`
  query comparisonOnlineMedia_getBrandPopularity(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
  ) {
    comparisonOnlineMedia_getBrandPopularity(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
    ) {
      name
      value
      __typename
    }
  }
`;

export const GET_BRAND_POSITIONING_ONLINE_MEDIA = gql`
  query comparisonOnlineMedia_getBrandPositioning(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
  ) {
    comparisonOnlineMedia_getBrandPositioning(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
    ) {
      name
      popularity
      positivity
      __typename
    }
  }
`;

export const GET_CHRONOLOGICAL_ARTICLE_BY_BRAND = gql`
  query comparisonOnlineMedia_getChronologicalArticleByBrand(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $validationList: [StreamValidation!]
  ) {
    comparisonOnlineMedia_getChronologicalArticleByBrand(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      validationList: $validationList
    ) {
      name
      values {
        timestamp
        value
      }
      __typename
    }
  }
`;

export const GET_CHRONOLOGICAL_MEDIA_BY_BRAND = gql`
  query comparisonOnlineMedia_getChronologicalMediaByBrand(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $validationList: [StreamValidation!]
  ) {
    comparisonOnlineMedia_getChronologicalMediaByBrand(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      validationList: $validationList
    ) {
      name
      values {
        timestamp
        value
      }
      __typename
    }
  }
`;

export const GET_ADVANCE_METRIC_ONLINE_MEDIA = gql`
  query comparisonOnlineMedia_getAdvanceMetricOnlineMedia(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
  ) {
    comparisonOnlineMedia_getAdvanceMetricOnlineMedia(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
    ) {
      category
      totalArticle
      totalMedia
      sentimentPositive
      sentimentNegative
      sentimentNeutral
      prValue
      __typename
    }
  }
`;

export const GET_COMPARISON_BY_ID = gql`
  query comparisonOnlineMedia_getComparisonById($comparisonId: Id!) {
    comparisonOnlineMedia_getComparisonById(comparisonId: $comparisonId) {
      id
      namespace
      displayName
      createdAt
      colors
      groups {
        id
        name
        displayName
        createdAt
        clippingList
        clippingListObject {
          id
          name
          createdAt
          startingDate: startDate
          phrases
          excludePhrases
        }
        labelList
      }
      __typename
    }
  }
`;

export const ONLINE_MEDIA_GET_LANGUAGES = gql`
  query onlineMedia_getLanguages {
    onlineMedia_getLanguages {
      name
      language
    }
  }
`;

export const ONLINE_MEDIA_GET_CLIPPING_V5 = gql`
  query onlineMedia_getClippingWithPagination(
    $projectId: Id!
    $page: Int!
    $limit: Int!
    $name: String!
  ) {
    onlineMedia_getClippingWithPagination(
      projectId: $projectId
      page: $page
      limit: $limit
      name: $name
    ) {
      result {
        id
        name
        createdAt
        startingDate
        phrases
        excludePhrases
      }
      totalPages
      totalRows
      page
      limit
    }
  }
`;

export const ONLINE_MEDIA_GET_CLIPPING_BY_ID_V5 = gql`
  query onlineMedia_getClippingByIdV5($clippingId: Id!) {
    onlineMedia_getClippingByIdV5(clippingId: $clippingId) {
      id
      name
      createdAt
      startingDate
      phrases
      excludePhrases
    }
  }
`;

export const ONLINE_MEDIA_GET_CLUSTERING = gql`
  query clusteringOnm_getClustering(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $mediaList: [String!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $languageList: [String!]!
    $clippingId: Id
    $includeKeywords: [String!]
    $excludeKeywords: [String!]
    $phrases: [String!]
    $validationList: [ValidationType!]
    $searchType: SearchType
    $queryStringBooleanOperator: QueryStringBooleanOperator
    $desiredClusterCount: Int!
    $minimumLabelLength: Int!
    $stopWords: [String!]
  ) {
    clusteringOnm_getClustering(
      projectId: $projectId
      start: $start
      end: $end
      mediaList: $mediaList
      granularity: $granularity
      timezone: $timezone
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      languageList: $languageList
      clippingId: $clippingId
      includeKeywords: $includeKeywords
      excludeKeywords: $excludeKeywords
      phrases: $phrases
      validationList: $validationList
      searchType: $searchType
      queryStringBooleanOperator: $queryStringBooleanOperator
      desiredClusterCount: $desiredClusterCount
      minimumLabelLength: $minimumLabelLength
      stopWords: $stopWords
    ) {
      label
      id
      weight
      originalIds
    }
  }
`;

export const ONLINE_MEDIA_GET_CLUSTERING_DETAIL = gql`
  query clusteringOnm_getClusteringDetail($originalIds: [String!]!) {
    clusteringOnm_getClusteringDetail(originalIds: $originalIds) {
      originalId
      title
      content
      mediaName
      datePublish
      link
    }
  }
`;

export const GET_CLUSTERING_LIMIT = gql`
  query clusteringOnm_getMaximumStream {
    clusteringOnm_getMaximumStream {
      onm
      sosmed
    }
  }
`;
